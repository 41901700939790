// import { loadDefaultJapaneseParser } from "budoux";

export const isValidCampaign = (until: string | null): boolean => {
  const today = new Date();
  return until ? new Date(until).getTime() - today.getTime() > 0 : true;
};

export const useBudoux = (text: string) => {
  return text;
  // const parser = loadDefaultJapaneseParser();
  // return parser.translateHTMLString(text);
};
