import React from "react";
import {
  Avatar,
  Box,
  Card,
  CardActionArea,
  Container,
  Grid,
  Link,
  Typography,
} from "@mui/material";
import { graphql, navigate, useStaticQuery } from "gatsby";
import LinkIcon from "@mui/icons-material/Link";
import { useBudoux } from "../../lib/helpers";

export const RelatedBlogs = ({ _id, name }: GatsbyTypes.SanityService) => {
  const {
    allSanityBlogPost: { edges },
  } = useStaticQuery<GatsbyTypes.AllPostsQuery>(graphql`
    query AllPosts {
      allSanityBlogPost {
        edges {
          node {
            title
            slug {
              current
            }
            _rawBody
          }
        }
      }
    }
  `);
  const relatedPosts = edges.filter(({ node }) => {
    const rawBody = node._rawBody as {
      markDefs:
        | []
        | [
            {
              _ref: string;
              _type: "internalLink" | string;
            }
          ];
    }[];
    return rawBody.filter(
      ({ markDefs }) =>
        markDefs.length > 0 &&
        markDefs.find(
          ({ _type, _ref }) => _type === "internalLink" && _ref === _id
        )
    ).length;
  });

  return (
    relatedPosts.length > 0 && (
      <Box my={12}>
        <Container maxWidth="md">
          <Typography component="h2" variant="h5" align="center" mb={2}>
            <span
              dangerouslySetInnerHTML={{
                __html: useBudoux(`${name}に関する記事`),
              }}
            />
          </Typography>
          <Box>
            <Grid container spacing={3}>
              {relatedPosts.map(({ node }, index) => {
                const { title, slug } = node;
                const url = `/blog/${slug.current}`;

                return (
                  <Grid item xs={12} sm={6} md={4} key={index} display="flex">
                    <Card
                      variant="outlined"
                      sx={{
                        display: "flex",
                        flexGrow: 1,
                      }}
                    >
                      <CardActionArea
                        sx={{
                          p: 1,
                          display: "flex",
                          alignItems: "stretch",
                          overflow: "hidden",
                        }}
                        onClick={() => {
                          navigate(url);
                        }}
                      >
                        <Box display="flex" flexGrow={1}>
                          <Avatar
                            variant="rounded"
                            sx={{
                              flexBasis: "20%",
                              minHeight: 64,
                              height: "auto",
                              backgroundColor: (theme) =>
                                theme.palette.info.light,
                            }}
                          >
                            <LinkIcon />
                          </Avatar>
                          <Box ml={1}>
                            <Link
                              color="inherit"
                              underline="none"
                              href={url}
                              onClick={(e) => {
                                e.preventDefault();
                              }}
                            >
                              {title}
                            </Link>
                          </Box>
                        </Box>
                      </CardActionArea>
                    </Card>
                  </Grid>
                );
              })}
            </Grid>
          </Box>
        </Container>
      </Box>
    )
  );
};
