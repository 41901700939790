import React, { useRef } from "react";
import { Campaign } from "@mui/icons-material";
import {
  Card,
  Avatar,
  Stack,
  Typography,
  CardActionArea,
  Link,
  styled,
} from "@mui/material";

export const CampaignBanner = (
  props: GatsbyTypes.SanityCampaign & { name?: string }
) => {
  const { ad, url, detail, name } = props;
  const clickableEl = useRef(null);
  const TargetParent = styled("div")({
    a: {
      textDecoration: "none",
      color: "inherit",
    },
  });

  return (
    <Card variant="outlined" sx={{ display: "flex", flexGrow: 1 }}>
      <CardActionArea
        sx={{
          p: 1,
          display: "flex",
          alignItems: "stretch",
          overflow: "hidden",
        }}
        onClick={() => {
          clickableEl.current?.click();
        }}
      >
        <Avatar
          variant="rounded"
          sx={{
            flexBasis: "25%",
            minHeight: 64,
            height: "auto",
            backgroundColor: (theme) => theme.palette.info.light,
          }}
        >
          <Campaign fontSize="large" />
        </Avatar>
        <Stack justifyContent="space-between" flexGrow={1} ml={1}>
          <Stack mb={2} lineHeight={1.4}>
            {ad ? (
              <TargetParent
                dangerouslySetInnerHTML={{ __html: ad.html }}
                ref={clickableEl}
                onClick={(e) => {
                  e.currentTarget.childNodes.forEach((node) => {
                    if (node.nodeName === "A") {
                      node.addEventListener("click", (e) => {
                        const anchorEl = e.currentTarget as HTMLAnchorElement;
                        anchorEl.setAttribute("target", "_blank");
                        anchorEl.setAttribute("rel", "noopener noreferrer");
                      });
                      node.dispatchEvent(
                        new MouseEvent("click", {
                          bubbles: false,
                        })
                      );
                    }
                  });
                }}
              />
            ) : (
              <Link
                ref={clickableEl}
                variant="subtitle2"
                href={url}
                underline="none"
                color="inherit"
                target="_blank"
                rel="noopener noreferrer"
              >
                {detail}
              </Link>
            )}
            {name && (
              <Typography
                component="span"
                variant="caption"
                color={(theme) => theme.palette.grey[600]}
              >
                via {name}
              </Typography>
            )}
          </Stack>
          <Typography
            align="right"
            variant="caption"
            color={(theme) => theme.palette.primary.light}
          >
            詳細を見る
          </Typography>
        </Stack>
      </CardActionArea>
    </Card>
  );
};
