import React from "react";
import { Box } from "@mui/system";
import { PageProps } from "gatsby";
import { Seo } from "../../components/Seo";
import { Layout } from "../../containers/Layout";
import { CampaignSection } from "./CampaignSection";
import { HeroSection } from "./HeroSection";
import { PriceSection } from "./PriceSection";
import { RelatedBlogs } from "./RelatedBlogs";

export default function Service({
  pageContext: { node },
}: PageProps<{}, { node: GatsbyTypes.SanityService }>) {
  const { name } = node;

  return (
    <Layout>
      <Seo
        title={`${name}のサービス紹介ページ`}
        description={`オンライン英会話スクール「${name}」の料金やキャンペーン情報を紹介しているページです。`}
      />
      <Box component="main">
        <HeroSection {...node} />
        <CampaignSection {...node} />
        <PriceSection {...node} />
        <RelatedBlogs {...node} />
      </Box>
    </Layout>
  );
}
