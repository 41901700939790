import React from "react";
import { Box, Container, Grid } from "@mui/material";
import { CampaignBanner } from "../../components/CampaignBanner";
import { isValidCampaign } from "../../lib/helpers";

export const CampaignSection = ({
  campaigns,
  url,
}: GatsbyTypes.SanityService) => {
  return (
    <Box my={4}>
      <Container maxWidth="md">
        <Grid container spacing={2}>
          {campaigns.map(
            (campaign) =>
              isValidCampaign(campaign.until) && (
                <Grid key={campaign._key} item xs={12} sm={6} display="flex">
                  <CampaignBanner
                    {...campaign}
                    url={campaign.url ? campaign.url : url}
                  />
                </Grid>
              )
          )}
        </Grid>
      </Container>
    </Box>
  );
};
