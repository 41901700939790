import React, { SyntheticEvent, useState } from "react";
import {
  Accordion,
  AccordionSummary,
  Stack,
  Typography,
  AccordionDetails,
  AccordionActions,
  Button,
  Box,
  Container,
} from "@mui/material";
import { ExpandMore } from "@mui/icons-material";

export const PriceSection = ({ plans }: GatsbyTypes.SanityService) => {
  const [expanded, setExpanded] = useState<string | false>("panel0");
  const handleChange =
    (panel: string) => (event: SyntheticEvent, isExpanded: boolean) => {
      setExpanded(isExpanded ? panel : false);
    };

  return (
    <Box my={12}>
      <Container maxWidth="md">
        <Typography component="h2" variant="h5" align="center" mb={2}>
          プラン一覧
        </Typography>
        <Box>
          {plans.map(
            ({ _key, title, price, description, url, type }, index) => (
              <Accordion
                key={_key}
                expanded={expanded === `panel${index}`}
                onChange={handleChange(`panel${index}`)}
              >
                <AccordionSummary
                  aria-controls={`${_key}-content`}
                  id={`${_key}-header`}
                  expandIcon={<ExpandMore />}
                >
                  <Stack
                    flexDirection="row"
                    flexGrow={1}
                    justifyContent="space-between"
                  >
                    <Typography component="p" variant="subtitle2">
                      {title}
                    </Typography>
                    <Stack flexDirection="row" alignItems="baseline" px={1}>
                      <Typography
                        component="p"
                        variant="subtitle2"
                        color="text.secondary"
                      >
                        ¥{price.data.toLocaleString()}
                        {price.moreThan && "~"}
                      </Typography>
                      <Typography
                        component="p"
                        variant="caption"
                        color="text.secondary"
                        pl={0.25}
                      >
                        /{type === "daily" ? "日" : "月"}
                      </Typography>
                    </Stack>
                  </Stack>
                </AccordionSummary>
                <AccordionDetails>
                  <Typography>{description}</Typography>
                </AccordionDetails>
                <AccordionActions>
                  <Button href={url} rel="noopener noreferrer" target="_blank">
                    詳細を見る
                  </Button>
                </AccordionActions>
              </Accordion>
            )
          )}
        </Box>
      </Container>
    </Box>
  );
};
